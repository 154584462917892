import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import arrowleft from '..//assets/images/arrowleft-black.svg'
import Image from 'next/image'
import Link from 'next/link'
import arrowRight from '..//assets/images/arro-right-white.svg'
import arrowRightBlack from '..//assets/images/arrowright-black.svg'
import ee1 from '..//assets/images/ee1.png'
import ee2 from '..//assets/images/ee2.png'
import ee3 from '..//assets/images/ee3.png'
import ee4 from '..//assets/images/ee4.png'
import trip from '..//assets/images/trip.png'
const NextButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className='slick-prev slick-prev2 768-991:!left-[-0.4rem] !flex items-center justify-center text-white !bg-white rounded-full 992-1199:!-left-7 1366-1439:!-left-[2.3rem] !-left-[2.2rem] !top-[58%] before:hidden !w-16 !h-16 !border-[6px] !border-solid !border-[#E4F4FB] z-[1] group transition duration-[.3s] ease-in-out hover:!bg-[#3DABC2]'
    >
      <Image
        src={arrowleft}
        alt='image'
        title='image'
        className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
      />
    </button>
  )
}
const PreviousButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className='slick-next slick-next2 768-991:!right-[-5px] !flex items-center justify-center text-white !bg-white rounded-full before:hidden !top-[58%] !w-16 !h-16 !border-[6px] !border-solid !border-[#E4F4FB] z-[1] group transition duration-[.3s] ease-in-out hover:!bg-[#3DABC2]'
    >
      <Image
        src={arrowRightBlack}
        alt='image'
        title='image'
        className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
      />
    </button>
  )
}
export default function unforgettableExperiences ({ article_ideas }) {
  const settings = {
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    dots: true,
    prevArrow: <NextButton />,
    nextArrow: <PreviousButton />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
          // centerMode: true,
          // centerPadding: '20px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // centerMode: true,
          // centerPadding: '20px'
        }
      }
    ]
  }
  return (
    <>
      <section className='text-black bg-[#E4F4FB] relative pt-12 pb-32 px-48 2560:px-[440px] 768-991:pb-16 768-991:px-6 992-1199:px-8 1680-1919:px-32 1600-1679:px-28 1440-1599:px-[6.5rem] 1366-1439:px-16 1200-1300:px-16'>
        <h3 className='font-poppins-semiBold text-xs  tracking-[3.6px] uppercase text-[#3DABC2]'>
          BLUEWATER TRAVEL FAVOURITES
        </h3>
        <div className='top-text flex items-end 768-991:items-center 1440-1599:items-center 992-1199:items-center 1366-1439:items-center justify-between'>
          <h4 className='text-[38px] 320-529:text-[24px] 320-529:pt-2 320-529:leading-7 768-991:text-[30px] 768-991:leading-8 1440-1599:text-[32px] 992-1199:text-[26px] 992-1199:leading-7 1366-1439:text-[30px] 1366-1439:leading-8 font-lato-extrabold  leading-10 1440-1599:leading-9'>
            Unforgettable Experiences
          </h4>
          <Link
            href='/top-10'
            className='btn-hover bg-[#F57415] 768-991:hidden py-3 px-10  text-base 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] text-white rounded-full border-[2px] border-solid border-[#F57415] flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#F57415]'
          >
            All Top Ideas
            <Image src={arrowRight} alt='image' title='image' />
          </Link>
        </div>

        <div className='w-full relative mt-[0.4rem] 768-991:mt-1 768-991:pb-5'>
          <div>
            <Slider {...settings}>
              {article_ideas?.map(item => (
                <div className='768-991:px-[30px]'>
                  <Link href={'/article/' + item?.slug}>
                    <div className='cards group h-full w-[95%] 768-991:w-full 992-1199:mx-auto 1680-1919:mx-auto 1600-1679:mx-auto 1440-1599:mx-auto bg-white rounded-[10px] 1366-1439:px-4 pb-5 px-5 min-h-[390px] my-[60px] cursor-pointer transition duration-[.3s] ease-in-out hover:bg-[#3DABC2] 320-529:!h-auto'>
                      <div className='card-image translate-y-[-25px] relative overflow-hidden rounded-[10px]'>
                        <Image
                          src={item?.image ? item?.image : ee1}
                          className='rounded-[10px] h-[220px] w-full object-cover transition duration-[.6s] ease-in-out'
                          alt='image'
                          title='image'
                          width={100}
                          height={100}
                        />
                      </div>
                      <div className='card-content px-4'>
                        <h4 className='text-lg 992-1199:text-[16px] font-poppins-semiBold  tracking-[-0.18px] text-black overflow-hidden line-clamp-2 transition duration-[.3s] ease-in-out min-h-[55px] group-hover:text-white'>
                          {item?.title}
                        </h4>
                        <p
                          className='min-h-[68px] text-[13px] text-[#4A748B]  leading-5 tracking-[0.26px] font-poppins-regular pt-4 overflow-hidden line-clamp-3 transition duration-[.3s] ease-in-out group-hover:opacity-0 '
                          dangerouslySetInnerHTML={{
                            __html: item?.short_description
                          }}
                        ></p>
                        <Link
                          href={'/article/' + item?.slug}
                          className='768-991:w-full font-poppins-semiBold text-sm  leading-6 tracking-[0.28px] flex items-center gap-3 text-white opacity-0 transition duration-[.3s] ease-in-out group-hover:opacity-100 group-hover:translate-y-[-10px]'
                        >
                          Explore more{' '}
                          <Image src={arrowRight} alt='image' title='image' />
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <Link
          href='/top-10'
          className='btn-hover bg-[#F57415] hidden 768-991:inline-flex 768-991:mt-8 320-529:py-3 320-529:px-8 320-529:text-[15px] py-4 px-10  text-base 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] text-white rounded-full border-[2px] border-solid border-[#F57415] items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#F57415]'
        >
          All Top Ideas
          <Image src={arrowRight} alt='image' title='image' />
        </Link>

        <Image
          src={trip}
          className='absolute 320-529:-bottom-[6px] 768-991:-bottom-[15px] -bottom-[35px] 2560:rotate-[-1deg] left-0 w-full pointer-events-none'
          alt='image'
          title='image'
        />
      </section>
    </>
  )
}
