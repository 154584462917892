'use-client'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from 'next/image'
import arrowleft from '/assets/images/arrowleft-black.svg'
import arrowRightBlack from '/assets/images/png/arrow-right-short.png'
import Link from 'next/link'
import arrowRight from '/assets/images/arro-right-white.svg'
import bdp1 from '/assets/images/bpd-1.png'
import ff1 from '/assets/images/ff1.png'
import ff3 from '/assets/images/ff3.png'
import lac8 from '/assets/images/lac8.png'
import gil4 from '/assets/images/gil4.png'
import star from '/assets/images/star2.svg'
import service from '/assets/images/service.png'
import postcards from '/assets/images/postcards.png'

const NextButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className='slick-prev slick-prev1 768-991:!left-[-0.4rem] 768-991:!top-[59%] !flex items-center justify-center 1600-1679:!top-[49%] text-white !bg-white rounded-full 992-1199:!-left-8 !-left-10 before:hidden !w-16 !h-16 !border-[6px] !border-solid !border-[#D6E8EF] z-[1] group transition duration-[.3s] ease-in-out hover:!bg-[#3DABC2]'
    >
      <Image
        src={arrowleft}
        alt='image'
        title='image'
        className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
      />
    </button>
  )
}
const PreviousButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className='slick-next slick-next1 768-991:!right-[-5px] 768-991:!top-[59%] !flex items-center justify-center 1600-1679:!top-[49%] text-white !bg-white rounded-full before:hidden !w-16 !h-16 !border-[6px] !border-solid !border-[#D6E8EF] z-[1] group transition duration-[.3s] ease-in-out hover:!bg-[#3DABC2]'
    >
      <Image
        src={arrowRightBlack}
        alt='image'
        title='image'
        className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
      />
    </button>
  )
}
export default function HomeBeneath ({ article_trip_reports }) {
  const settings = {
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    dots: true,
    prevArrow: <NextButton />,
    nextArrow: <PreviousButton />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <section className='bg-[#D6E8EF] text-black relative pt-12 pb-52 px-48 2560:px-[440px] 768-991:px-6 992-1199:px-8 1680-1919:px-32 1600-1679:px-28 1440-1599:px-[6.5rem] 1366-1439:px-16 1200-1300:px-16'>
      <h3 className='font-poppins-semiBold text-xs  tracking-[3.6px] uppercase text-[#3DABC2]'>
        LATEST TRIP REPORTS & ARTICLES
      </h3>
      <div className='top-text flex items-end justify-between pt-3'>
        <h4 className='768-991:w-full text-[38px] 320-529:text-[24px] 320-529:leading-7 320-529:w-full 1600-1679:w-[23%] 768-991:text-[30px] 768-991:leading-8 font-lato-extrabold  leading-10 w-[20%] 992-1199:text-[26px] 992-1199:leading-7 1366-1439:text-[30px] 1366-1439:leading-8 1680-1919:w-[25%] 1440-1599:w-[25%] pt-3 1440-1599:text-[32px]'>
          Stories Beneath The Waves
        </h4>
        <Link
          href='/destination-finder'
          className='btn-hover bg-[#F57415] 768-991:hidden py-3 px-10  text-base 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] text-white rounded-full border-[2px] border-solid border-[#F57415] flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#F57415]'
        >
          All Trip Reports
          <Image src={arrowRight} alt='image' title='image' />
        </Link>
      </div>

      <div className='w-full relative mt-[0.4rem] 768-991:mt-1 768-991:pb-6'>
        <div>
          <Slider {...settings}>
            {article_trip_reports?.map(item => (
              <div className='768-991:px-[30px]'>
                <Link
                  href={'/article/' + item?.slug}
                  className='cards inline-block h-[460px] w-[95%] 768-991:w-full 992-1199:mx-auto 1680-1919:mx-auto 1600-1679:mx-auto 1440-1599:mx-auto bg-white rounded-[10px] 1366-1439:px-4 pb-5 px-5 my-[60px] cursor-pointer group transition duration-[.3s] ease-in-out hover:bg-[#3DABC2] 320-529:!h-auto'
                >
                  <div className='card-image shadow-contact translate-y-[-25px] relative overflow-hidden rounded-[10px]'>
                    <Image
                      src={item?.image ? item?.image : ff1}
                      width={100}
                      height={100}
                      className='rounded-[10px] h-[220px] 1260-1365:h-[150px] w-full object-cover transition duration-[.6s] ease-in-out'
                      alt='image'
                      title='image'
                    />
                  </div>
                  <div className='card-content px-4'>
                    <h4 className='text-lg 992-1199:text-[16px] font-poppins-semiBold  tracking-[-0.18px] text-black overflow-hidden line-clamp-2 transition duration-[.3s] ease-in-out min-h-[55px]  group-hover:text-white'>
                      {item?.title}
                    </h4>
                    <p
                      className='text-[13px] text-[#4A748B]  leading-5 tracking-[0.26px] font-poppins-regular pt-4 overflow-hidden line-clamp-5 min-h-[116px] transition duration-[.3s] ease-in-out  group-hover:text-white'
                      dangerouslySetInnerHTML={{
                        __html: item?.short_description
                      }}
                    ></p>
                    <div className='card-detail flex items-center gap-2 pt-4'>
                      <h6 className='text-[#B6D6E2] text-center font-poppins-semiBold  text-[13px] leading-6 tracking-[0.13px] group-hover:text-white'>
                        <span className='line-clamp-1'>{item?.published_date}</span>
                      </h6>
                      <span className='text-[#B6D6E2] text-center font-poppins-semiBold  text-[13px] leading-6 tracking-[0.13px] flex items-center group-hover:text-white'>
                        <Image
                          src={star}
                          className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
                          alt='image'
                          title='image'
                        />
                        <span className='line-clamp-1'>{item?.authored_by}</span>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <Link
        href='/destination-finder'
        className='btn-hover bg-[#F57415] hidden 768-991:inline-flex 768-991:mt-8 320-529:py-3 320-529:px-8 320-529:text-[15px] py-4 px-10  text-base 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] text-white rounded-full border-[2px] border-solid border-[#F57415] items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#F57415]'
      >
        All Trip Reports
        <Image src={arrowRight} alt='image' title='image' />
      </Link>

      <Image
        src={service}
        className='absolute -top-[80px] 768-991:-top-[20px] 1260-1365:-top-[70px] 992-1199:-top-[55px] 1366-1439:-top-[75px] 1440-1599:-top-[80px] 1680-1919:-top-[95px] 1600-1679:-top-[90px] left-0 w-full'
        alt='image'
        title='image'
      />
      <Image
        src={postcards}
        className='absolute bottom-0 left-0 w-full'
        alt='image'
        title='image'
      />
    </section>
  )
}
