import React, {useState, useEffect, useRef} from 'react';
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import {getHome, getSeoTags} from "../services/home";
import {getCenterSearch} from "../services/destinationFinder";
gsap.registerPlugin(ScrollTrigger);
const useHome = () => {
    const [page,setPage] = useState([])
    const [centerMenus,setCenterMenus] = useState([])
    const [loading, setLoading] = useState(true);
    const [seoTags ,setSeoTags] = useState([]);



    useEffect(() => {
        getData().finally(()=> {
                setLoading(false)
        })
        getTags()
    }, []);

    const getData = async () => {
        const res =   await getHome();
        if (res?.status === 'success'){
            setPage(res?.data?.page);
        }
        const response = await getCenterSearch();
        if (response?.status === 'success'){
            setCenterMenus(response?.data?.data?.search_destination);
        }
    };
    const getTags = async () => {
        let res =  await getSeoTags('home');
        if (res?.data?.page?.sections?.length > 0){
            setSeoTags(JSON.parse(res?.data?.page?.sections[0]?.description))
        }
    }
    return [
        page,
        centerMenus,
        loading,
        seoTags
    ]

};

export {useHome};
