import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from 'next/image'
import arrowleft from '..//assets/images/arrowleft-black.svg'
import Link from 'next/link'
import arrowRight from '..//assets/images/arro-right-white.svg'
import arrowRightBlack from '..//assets/images/arrowright-black.svg'
import star from '..//assets/images/star2.svg'
import postcards from '..//assets/images/postcards.png'
import ff1 from '..//assets/images/ff1.png'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'

export default function StoriesBeneath ({
  articles,
  showBackground,
  showImage,
  customPadding,
  showButton = true,
  changeBorder
}) {
  const router = useRouter()
  const [isImage, setIsImage] = useState(false)
  const [related, setArticle] = useState([])

  const NextButton = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        className={`slick-prev slick-prev3 768-991:!hidden !flex items-center justify-center text-white !bg-white rounded-full 992-1199:!-left-7 777-991:!left-[-15px] !-left-[2.3rem] !top-[54%] 1600-1679:!top-[53%] 1260-1365:!top-[52%] before:hidden !w-16 !h-16 !border-[6px] !border-solid ${
          changeBorder ? '!border-[#E4F4FB]' : '!border-[#D6E8EF]'
        } z-[1] group transition duration-[.3s] ease-in-out hover:!bg-[#3DABC2]`}
      >
        <Image
          src={arrowleft}
          alt="image" title="image"
          className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
        />
      </button>
    )
  }
  const PreviousButton = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        className={`slick-next slick-next3 768-991:!hidden !flex items-center justify-center text-white !bg-white rounded-full before:hidden !top-[54%] 1600-1679:!top-[53%] 1260-1365:!top-[52%] 777-991:!right-[-10px] !w-16 !h-16 !border-[6px] !border-solid ${
          changeBorder ? '!border-[#E4F4FB]' : '!border-[#D6E8EF]'
        } z-[1] group transition duration-[.3s] ease-in-out hover:!bg-[#3DABC2]`}
      >
        <Image
          src={arrowRightBlack}
          alt="image" title="image"
          className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
        />
      </button>
    )
  }

  const settings = {
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: true,
    dots: true,
    autoplay: true,
    prevArrow: <PreviousButton />,
    nextArrow: <NextButton />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: '20px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px'
        }
      }
    ]
  }
  useEffect(() => {
    setArticle(articles)
    if (router?.pathname === '/guided-group-trips/[...index]') {
      setIsImage(true)
    }
  }, [router])

  return (
    related?.length > 0 && (
      <section
        className={` text-black ${
          showBackground ? 'bg-[#D6E8EF]' : ''
        }  relative pt-12 pb-${customPadding} px-48 768-991:px-6 530-767:pe-0 320-529:pe-0 992-1199:px-8 1680-1919:px-32 1600-1679:px-28 1440-1599:px-[6.5rem] 1366-1439:px-16 1200-1300:px-16`}
      >
        <h3 className='font-poppins-semiBold text-xs  tracking-[3.6px] uppercase text-[#3DABC2]'>
          LATEST TRIP REPORTS & ARTICLES
        </h3>
        <div className='top-text flex items-end justify-between'>
          <h4 className='768-991:w-full text-[38px] 320-529:text-[24px] 320-529:leading-7 320-529:w-full 1600-1679:w-[23%] 768-991:text-[30px] 768-991:leading-8  font-lato-bold  leading-10 w-[20%] 992-1199:text-[26px] 992-1199:leading-7 1366-1439:text-[30px] 1366-1439:leading-8 1680-1919:w-[25%] 1440-1599:w-[25%] pt-3'>
            Stories Beneath The Waves
          </h4>
          {showButton && (
            <Link
              href='javascript:void(0)'
              className='btn-hover bg-[#F57415] 768-991:hidden py-3 px-10  text-base 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] text-white rounded-full border-[2px] border-solid border-[#F57415] flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#F57415]'
            >
              All Trip Reports
              <Image src={arrowRight} alt="image" title="image" />
            </Link>
          )}
        </div>

        <div className='w-full track-width relative mt-[0.4rem] 768-991:mt-1 768-991:pb-5'>
          {/*<div>*/}

          <Slider {...settings}>
            {related?.map(article => (
              <div className='768-991:px-[25px]'>
                <Link
                  href='javascript:void(0)'
                  className='cards inline-block h-full w-[95%] 768-991:w-full 992-1199:mx-auto 1680-1919:mx-auto 1260-1365:min-h-[400px] 1600-1679:mx-auto 1440-1599:mx-auto bg-white rounded-[10px] 1366-1439:px-4 pb-5 px-5 min-h-[390px] 1680-1919:min-h-[450px] 1600-1679:min-h-[450px] my-[60px] cursor-pointer group transition duration-[.3s] ease-in-out hover:bg-[#3DABC2]'
                >
                  <div className='card-image shadow-contact translate-y-[-25px] relative overflow-hidden rounded-[10px]'>
                    <Image
                      src={article?.image ? article?.image : ff1}
                      width={100}
                      height={100}
                      className='rounded-[10px] h-[220px] 1260-1365:h-[150px] w-full object-cover transition duration-[.6s] ease-in-out'
                      alt="image" title="image"
                    />
                  </div>
                  <div className='card-content px-4'>
                    <h4 className='text-lg 992-1199:text-[16px] font-poppins-semiBold  tracking-[-0.18px] text-black overflow-hidden line-clamp-2 transition duration-[.3s] ease-in-out min-h-[55px]  group-hover:text-white'>
                      {article?.title}
                    </h4>
                    <p className='text-[13px] text-[#4A748B]  leading-5 tracking-[0.26px] font-poppins-regular pt-4 overflow-hidden line-clamp-5 transition duration-[.3s] ease-in-out  group-hover:text-white'>
                      Observing and photographing humpback whales underwater is
                      one of our favorite ocean activities, and is something
                      that never gets old. Every year since 2014...
                    </p>
                    <div className='card-detail flex items-center gap-2 pt-4'>
                      <h6 className='text-[#B6D6E2] font-poppins-semiBold  text-[13px] leading-6 tracking-[0.13px]  group-hover:text-white'>
                        {article.date ? article.date : ''}
                      </h6>
                      <span className='text-[#B6D6E2] font-poppins-semiBold  text-[13px] leading-6 tracking-[0.13px] flex items-center  group-hover:text-white'>
                        <Image
                          src={star}
                          className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
                          alt="image" title="image"
                        />
                        {article.authored_by}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </Slider>

          {/*</div>*/}
        </div>

        <Link
          href='javascript:void(0)'
          className='btn-hover bg-[#F57415] hidden 768-991:inline-flex 768-991:mt-8 320-529:py-3 320-529:px-8 320-529:text-[15px] py-4 px-10  text-base 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] text-white rounded-full border-[2px] border-solid border-[#F57415] items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#F57415]'
        >
          All Trip Reports
          <Image src={arrowRight} alt="image" title="image" />
        </Link>
        {showImage && (
          <Image
            src={postcards}
            className='absolute bottom-0 left-0 w-full pointer-events-none'
            alt="image" title="image"
          />
        )}
      </section>
    )
  )
}
