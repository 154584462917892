import {Base_url} from "../utils/constant";

const getDestinationFinder = async (page,year_month,marine_highlight_id,dive_highlight_id,region_id,destination_id) => {
    let res = await fetch(
        Base_url+`/api/destinations-finder?${new URLSearchParams({
            page,
            year_month,
            marine_highlight_id,
            dive_highlight_id,
            region_id,
            destination_id
        })}`,
        {
            method: "GET",
            headers: new Headers({ "content-type": "application/json" }),
        }
    );
    return await res?.json();
};
const getCenterSearch = async () => {
    let res = await fetch(
        Base_url+`/api/search-collections`,
        {
            method: "GET",
            headers: new Headers({ "content-type": "application/json" }),
        }
    );
    return await res?.json();
};
const searchDestinationFinder = async (year_month,marine_highlight_id,dive_highlight_id,region_id,destination_id) => {
    let res = await fetch(
        Base_url+`/api/destinations-finder?${new URLSearchParams({
            year_month,
            marine_highlight_id,
            dive_highlight_id,
            region_id,
            destination_id
        })}`,
        {
            method: "GET",
            headers: new Headers({ "content-type": "application/json" }),
        }
    );
    return await res?.json();
};
export {getDestinationFinder,searchDestinationFinder,getCenterSearch};