import React, { useState, useRef, useEffect } from 'react'
import Image from 'next/image'
import geoImage from '/assets/images/geo.svg'
import arrowWhiteImage from '/assets/images/arrowWhite.svg'
import globeImage from '/assets/images/Globe.png'
import sailboatImage from '/assets/images/Sailboat.png'
import sunshadeImage from '/assets/images/Sunshade.png'
import tripsImage from '/assets/images/Trips.png'
import Link from 'next/link'
import {headerSearch, mainSearch} from "../services/dropdown";
import { useRouter } from 'next/router'
function CenterDropdown ({centerMenus}) {


  const router = useRouter();
  const [activeTab, setActiveTab] = useState(0)
  const [isMobile, setIsMobile] = useState(false);
  const [resortItems, setResortItems] = useState([]);
  const [resortMainItems, setResortMainItems] = useState([]);
  const [liveaboardItems, setLiveaboardItems] = useState([]);
  const [liveaboardMainItems, setLiveaboardMainItems] = useState([]);
  const [groupTrips, setGroupTrips] = useState([]);
  const [articles, setArticles] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [specialOffer, setSpecialOffer] = useState([]);
  const [val, setVal] = useState("");
  const handleTabClick = index => {
    setActiveTab(index)
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    };
  
    window.addEventListener("resize", handleResize);

    if (window.innerWidth < 992) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false)
  const [isDestination, setIsDestination] = useState(false)
  const [selectedDestination, setSelectedDestination] = useState('')
  const [checkboxValue, setCheckboxValue] = useState('')

  const dropdownRef = useRef(null)
  const toggleDropdown = () => {
    setIsDestination(false)
    setIsOpen(!isOpen)
  }
  const closeDropdown = () => {
    setIsOpen(false)
  }
  const handleDropdownClick = () => {
    if (isOpen) {
      closeDropdown()
    } else {
      toggleDropdown()
    }
  }
  const handleLinkClick = destination => {
    setSelectedDestination(destination)
    closeDropdown()
  }
  const handleButtonClick = () => {
    closeDropdown()
  }
  const handleDestinationClick = () => {
    setIsOpen(false)
    selectedDestination
      ? setIsDestination(!isDestination)
      : setIsDestination(false)

    router.push(`/search?query=${val}`)
  }
  const handleCheckboxChange = (event, value) => {
    setCheckboxValue(value)
  }

  const [searchText,setSearchText] = useState("");

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  }

  const centerRef = useRef(null);
  useEffect(()=>{
    function handleClickOutside(event) {
      if (centerRef.current && !centerRef.current.contains(event.target)) {
        setVal("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  })

  const handleValChange = async (e) => {
    const value = e.target.value.toLowerCase();
    setVal(value);
    if (value.length > 2){
      let res = await headerSearch(value);
      if (res?.data?.resorts?.length > 0){
        setResortItems(res?.data?.resorts)
      }else {
        setResortItems([])
      }
      if (res?.data?.liveaboards?.length > 0){
        setLiveaboardItems(res?.data?.liveaboards)
      }if (res?.data?.group_trips?.length > 0){
        setGroupTrips(res?.data?.group_trips)
      }if (res?.data?.articles?.length > 0){
        setArticles(res?.data?.articles)
      }
      if (res?.data?.destinations?.length > 0){
        setDestinations(res?.data?.destinations)
      }if (res?.data?.special_offer?.length > 0){
        setSpecialOffer(res?.data?.special_offer)
      }if (res?.data?.liveaboard_main?.length > 0){
        setLiveaboardMainItems(res?.data?.liveaboard_main)
      }if (res?.data?.resort_main?.length > 0){
        setResortMainItems(res?.data?.resort_main)
      }

    }
  };

  return (
    <>
      <div ref={centerRef}
        className={`flex 320-529:items-end 320-529:px-2 320-529:py-2 320-529:gap-1 items-end gap-4 bg-white search-shadow p-2 ps-4 pe-3 rounded-[6px] w-[32%] 1200-1300:w-[39%] 768-991:w-full 992-1199:w-[40%] 1680-1919:w-[35%] 1600-1679:w-[38%] 1440-1599:w-[36%] 1366-1439:w-[35%] cursor-pointer relative`}
      >
        <Image
          src={geoImage}
          alt="Geo"
          width={20}
          height={20}
          className="pb-[6px]"
        />
        <div
          className="w-full pt-1 pb-2"
          id="dropdown-button2"
          onClick={() => {
            toggleDropdown();
          }}
        >
          <h3 className="text-[#B6D6E2] uppercase text-xs 320-350:text-[10px] font-poppins-semiBold  leading-6 tracking-[2.4px]">
            FIND YOUR DESTINATION
          </h3>
          <input
            placeholder={searchText ? "" : "Where do you want to go?"}
            id="dropText"
            value={val}
            onChange={handleValChange}
            className="w-full pe-[50px] bg-white placeholder:text-black outline-none text-[25px] leading-[25px] 320-350:text-[14px] 768-991:text-[20px] 320-529:text-[17px] 1440-1599:text-[20px] 992-1199:text-[18px] 1366-1439:text-[20px] text-black font-lato-bold "
          />
          {val && <div className={`search-loader absolute right-24 top-[50%] translate-y-[-50%] ${(resortItems?.length === 0 && liveaboardItems?.length === 0 && articles?.length === 0 && groupTrips?.length === 0 && destinations?.length === 0) ? '' : 'hidden'}`}></div>}
            
        </div>
        <Link
          href="javascript:void(0)"
          className={`rounded-[10px] bg-[#3DABC2] next-dest h-full px-5 flex items-center justify-center ms-auto hover:bg-[#D85C02]`}
          onClick={() => {
            handleDestinationClick();
          }}
        >
          <Image src={arrowWhiteImage} alt="Arrow" width={24} height={24} />
        </Link>
        {
          val &&
          <div id="style-3"
            className={`absolute max-h-[500px] overflow-y-auto min-w-[100%] top-[77px] 768-991:top-[80px] 1440-1599:top-[78px] 992-1199:top-[76px] 1366-1439:top-[76px] left-0 z-[1] px-4 py-4 w-full transition-all duration-600 ease-in-out bg-[#FFF] rounded-bl-[10px] rounded-br-[10px]`}
        >
            {liveaboardMainItems.length > 0 && (
                liveaboardMainItems.map((item, index) => (
                    <div
                        className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                    >
                      <Link href={(item?.type == 'parent'? "/liveaboard-destination/"+item?.parent_slug :'/liveaboard/'+item?.parent_slug+'/'+item?.slug)}>
                        <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                          <Image
                              src={sunshadeImage}
                              className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                              alt="image"
                              width={24}
                              height={24}
                          />
                          <div className="flex flex-col">
                            <h6>{item.title}</h6>
                            <span className="text-[12px] text-[#4A748B] group-hover:text-[#FFF] font-poppins-regular">
                              {item.parent_title}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                )))}
            {resortMainItems.length > 0 && (
                resortMainItems.map((item, index) => (
                    <div
                        key={index}
                        className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                    >
                      <Link href={"/resort-destination/"+ (item?.parent_slug ? item?.parent_slug : item?.slug) }>
                        <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                          <Image
                              src={sunshadeImage}
                              className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                              alt="image"
                              width={24}
                              height={24}
                          />
                          <div className="flex flex-col">
                            <h6>{item.title} Resort</h6>
                            <span className="text-[12px] text-[#4A748B] group-hover:text-[#FFF] font-poppins-regular">
                          {item.parent_title}
                        </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                ))
            )}
            {groupTrips.length > 0 && (
                groupTrips.map((item, index) => (
                    <div
                        key={index}
                        className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                    >
                      <Link href={"/group-trip/"+item?.slug}>
                        <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                          <Image
                              src={sunshadeImage}
                              className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                              alt="image"
                              width={24}
                              height={24}
                          />
                          <div className="flex flex-col">
                            <h6>{item.title} Group Trip</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                ))
            )}
            {specialOffer.length > 0 && (
                specialOffer.map((item, index) => (
                    <div
                        key={index}
                        className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                    >
                      <Link href={(item?.type === 'Resort')?'/resort/'+item?.slug:'/liveaboard/'+item?.parent_slug+'/'+item?.slug}>
                        <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                          <Image
                              src={sunshadeImage}
                              className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                              alt="image"
                              width={24}
                              height={24}
                          />
                          <div className="flex flex-col">
                            <h6>{item.title} Special Offer</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                ))
            )}
            {destinations.length > 0 && (
                destinations.map((item, index) => (
                    <div
                        key={index}
                        className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                    >
                      <Link href={(item?.parent ? '/sub-destination/'+item?.parent?.slug+'/'+item?.slug : '/destination/'+item?.slug )}>
                        <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                          <Image
                              src={sunshadeImage}
                              className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                              alt="image"
                              width={24}
                              height={24}
                          />
                          <div className="flex flex-col">
                            <h6>{item.title} {(item?.parent ? 'Sub Destination' : 'Destination' )}</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                ))
            )}
            {articles.length > 0 && (
                articles.map((item, index) => (
                    <div
                        key={index}
                        className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                    >
                      <Link href={"/article/"+item?.slug}>
                        <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                          <Image
                              src={sunshadeImage}
                              className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                              alt="image"
                              width={24}
                              height={24}
                          />
                          <div className="flex flex-col">
                            <h6>{item.title} Article</h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                ))
            )}
            {resortItems.length > 0 && (
                resortItems.map((item, index) => (
                    <div
                        key={index}
                        className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                    >
                      <Link href={"/resort-destination/"+ (item?.parent_slug ? item?.parent_slug : item?.slug) }>
                        <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                          <Image
                              src={sunshadeImage}
                              className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                              alt="image"
                              width={24}
                              height={24}
                          />
                          <div className="flex flex-col">
                            <h6>{item.title}</h6>
                            <span className="text-[12px] text-[#4A748B] group-hover:text-[#FFF] font-poppins-regular">
                          {item.parent_title}
                        </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                ))
            )}
            {liveaboardItems.length > 0 && (
                liveaboardItems.map((item, index) => (
                    <div
                        className="item bg-white group hover:bg-[#3DABC2] rounded-[7px] transition-all duration-600 ease-in-out"
                    >
                      <Link href={(item?.type == 'parent'? "/liveaboard-destination/"+item?.parent_slug :'/liveaboard/'+item?.parent_slug+'/'+item?.slug)}>
                        <div className="flex items-center gap-3 font-poppins-semiBold  text-[18px] 1440-1599:text-[16px] 1366-1439:text-[16px] 992-1199:text-[16px] leading-[22px] text-black group-hover:text-white px-[10px] py-[10px] transition-all duration-600 ease-in-out">
                          <Image
                              src={sunshadeImage}
                              className="group-hover:filter group-hover:brightness-0 group-hover:invert-[1]"
                              alt="image"
                              width={24}
                              height={24}
                          />
                          <div className="flex flex-col">
                            <h6>{item.title} Liveaboard</h6>
                            <span className="text-[12px] text-[#4A748B] group-hover:text-[#FFF] font-poppins-regular">
                              {item.parent_title}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                )))}
            {(resortItems?.length === 0 && liveaboardItems?.length === 0 && articles?.length === 0 && groupTrips?.length === 0 && destinations?.length === 0 && specialOffer?.length === 0) && (
                <div className="text-[#4A748B] text-center font-poppins-semiBold  text-[15px]">
                  No results found.
                </div>
            )}
        </div>
        }

      </div>
    </>
  );
}
export default CenterDropdown
