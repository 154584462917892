'use client'
import Image from 'next/image'
import bannerVideo from '/assets/images/banner.gif'
import Head from 'next/head'
import bb1 from '/assets/images/bb1.png'
import bb2 from '/assets/images/bb2.png'
import bb3 from '/assets/images/bb3.png'
import bb4 from '/assets/images/bb4.png'
import bb5 from '/assets/images/bb5.png'
import arrowRight from '/assets/images/arro-right-white.svg'
import bb9 from '/assets/images/bb9.svg'
import bb10 from '/assets/images/bb10.svg'
import bb11 from '/assets/images/bb11.svg'
import bb12 from '/assets/images/bb12.svg'
import service from '/assets/images/service.png'
import arrowleft from '/assets/images/arrowleft-black.svg'
import dd1 from '/assets/images/dd1.png'
import calendarCheck from '/assets/images/calendarCheck.svg'
import arrowRightBlack from '/assets/images/arrowright-black.svg'
import arrowDown from '/assets/images/png/arrow-right.png'
import geo2 from '/assets/images/geo2.svg'
import dd2 from '/assets/images/dd2.png'
import dd3 from '/assets/images/dd3.png'
import experience from '/assets/images/experience.png'
import abLeft from '/assets/images/ab-left.gif'
import abRight from '/assets/images/ab-right.gif'
import wave from '/assets/images/wave.gif'
import Link from 'next/link'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import UnforgettableExperiences from '../components/unforgettableExperiences.js'
import StoriesBeneath from '../components/storiesBeneath'
import Testimonial from '../components/testimonial'
import Footer from '../components/layouts/footer'
import CenterDropdown from '../components/center-dropdown'
import Header from '../components/layouts/header'
import HomeBeneath from '../components/homeBeneath.js'
import { useHome } from '../hooks/useHome'
import { useEffect, useState } from 'react'
import { gsap } from 'gsap'
import SpinnerLoading from '../components/loading.js'
const NextButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className='slick-prev slick-prev1 768-991:!left-[-0.4rem] !flex items-center justify-center 1600-1679:!top-[49%] text-white !bg-white rounded-full 992-1199:!-left-7 !-left-10 before:hidden !w-16 !h-16 !border-[6px] !border-solid !border-[#D6E8EF] z-[1] group transition duration-[.3s] ease-in-out hover:!bg-[#3DABC2]'
    >
      <Image
        src={arrowleft}
        alt='image'
        title='image'
        className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
      />
    </button>
  )
}
const PreviousButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className='slick-next slick-next1 768-991:!right-[-5px] !flex items-center justify-center 1600-1679:!top-[49%] text-white !bg-white rounded-full before:hidden !w-16 !h-16 !border-[6px] !border-solid !border-[#D6E8EF] z-[1] group transition duration-[.3s] ease-in-out hover:!bg-[#3DABC2]'
    >
      <Image
        src={arrowRightBlack}
        alt='image'
        title='image'
        className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
      />
    </button>
  )
}
export default function Home () {
  const [page, centerMenus, loading,seoTags] = useHome()
  const settings = {
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    dots: true,
    prevArrow: <NextButton />,
    nextArrow: <PreviousButton />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
          // centerMode: true,
          // centerPadding: '20px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // centerMode: true,
          // centerPadding: '20px'
        }
      }
    ]
  }

  const [expandRegions, setExpandRegions] = useState({});

  const toggleExpandRegions = (index, e) => {
    e.preventDefault();
    setExpandRegions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  useEffect(() => {

    const handleScroll = () => {
      const secondSection = document.getElementById('home-second-section')
      const scrollPosition = window.scrollY
      const secondSectionPosition = secondSection.offsetTop

      if (
        scrollPosition >= secondSectionPosition &&
        !secondSection.classList.contains('hidden')
      ) {
        gsap.to('.scroll-bottom', { opacity: 0, duration: 1 })
      } else if (
        scrollPosition < secondSectionPosition &&
        secondSection.classList.contains('hidden')
      ) {
        gsap.to('.scroll-bottom', { opacity: 1, duration: 1 })
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Head>
        <title>{page?.title? page?.title :'Scuba diving travel agency - Book Liveaboards and Resorts'} - Bluewater Dive Travel</title>
        <meta
          name='description'
          content=''
        />
        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="noindex,nofollow" />
        <meta name="google" content="nositelinkssearchbox" />
        <meta
            name='title'
            content={
              seoTags?.meta_title
                  ? seoTags?.meta_title
                  : ''
            }
        />
        <meta
            name='description'
            content={
              seoTags?.meta_description
                  ? seoTags?.meta_description
                  : ''
            }
        />
        <meta
            name='abstract'
            content={
              seoTags?.meta_abstract
                  ? seoTags?.meta_abstract
                  : ''
            }
        />
        <meta
            name='keywords'
            content={
              seoTags?.meta_keywords
                  ? JSON.parse(seoTags?.meta_keywords)[0]?.value
                  : ''
            }
        />
        <link rel='icon' href='/favicon.ico' />
        { (seoTags)  && (
            seoTags?.seo?.map(seo =>
                seo?.seo_type === 'link' ? (
                    <>
                      <link rel={seo?.seo_name} href={seo?.seo_value} />
                    </>
                ) : (
                    <>
                      <meta property={seo?.seo_name} content={seo?.seo_value} />
                    </>
                )
            )
        )}
      </Head>
      <Header />
      {loading && <SpinnerLoading/>}
      <section className='text-black w-full h-[90vh] 768-991:px-6 768-991:justify-end 768-991:pb-[260px] banner flex flex-col items-center justify-center relative'>
        {page?.banner_video ? (
          <video
            src={page?.banner_video}
            autoPlay
            loop
            muted
            playsInline
            className='absolute top-0 left-0 w-full h-[100vh] -z-[1] object-cover pointer-events-none'
          />
        ) : (
          <Image
            src={bannerVideo}
            className='absolute top-0 left-0 w-full h-[100vh] -z-[1] object-cover pointer-events-none'
            alt='image'
            title='image'
          />
        )}

        <h1 className='text-[70px] 1200-1300:text-[55px] 1200-1300:w-[40%] 320-529:w-[100%] 320-529:text-[32px] 320-529:leading-[40px] 768-991:me-auto 768-991:w-[50%] 768-991:leading-[50px] 768-991:text-[45px] 768-991:font-lato 1440-1599:text-[60px] 992-1199:text-[45px] 992-1199:w-[40%] 992-1199:leading-[45px] 1366-1439:text-[52px] 1366-1439:w-[35%] 1366-1439:leading-[55px]  leading-[65px] tracking-[-2.1px] text-white font-fraunces-bold w-[33%] 1680-1919:w-[35%] 1600-1679:pl-[5px] 1600-1679:w-[39%] 1440-1599:w-[37%] pb-3'>
          Dive into your dream destination
        </h1>
        {/*<div className="flex 320-529:items-end 320-529:px-2 320-529:py-2 320-529:gap-1 items-end gap-5 bg-white search-shadow p-2 ps-4 pe-3 rounded-[6px] w-[30%] 768-991:w-full 992-1199:w-[40%] 1680-1919:w-[35%] 1600-1679:w-[35%] 1440-1599:w-[35%] 1366-1439:w-[35%] cursor-pointer relative">*/}
        <CenterDropdown centerMenus={centerMenus} />
        <div className='scroll-bottom flex flex-col items-center gap-4 translate-y-[60px] 768-991:translate-y-[30px]'>
          <h6 className='font-poppins-semiBold text-[12px] text-[#7EE9FF] uppercase tracking-[5px]'>
            scroll and explore
          </h6>
          <Image src={arrowDown} alt='images' className='rotate-[90deg]' />
        </div>
        {/*</div>*/}
        <div className='banner-bottom 2560:w-[66%] absolute -bottom-5 320-529:gap-1 320-529:items-start 768-991:w-full 768-991:px-6 768-991:gap-2 768-991:bottom-[60px] 1600-1679:-bottom-10 1440-1599:-bottom-10 992-1199:-bottom-10 992-1199:w-[95%] 992-1199:gap-3 1366-1439:-bottom-10 1366-1439:w-[90%] 1366-1439:gap-10 left-[50%] translate-x-[-50%] w-[80%] 1200-1300:w-[90%] 1200-1300:gap-10 1680-1919:w-[85%] 1600-1679:w-[86%] 1440-1599:w-[85%] 1600-1679:gap-10 1440-1599:gap-10 flex items-center justify-between'>
          <Link href='/article-list?id=2' className='bottom-card max-w-[280px] min-h-[100px] 320-529:px-1 320-529:py-2 320-529:h-auto 320-529:min-h-full 768-991:flex-col 768-991:p-3 768-991:gap-3 768-991:w-[20%] 768-991:min-h-[120px] flex items-center gap-6 p-5 bg-[#D6E8EF] rounded-[10px] group transition duration-[.3s] ease-in-out hover:bg-[#3DABC2]'>
            <Image
              src={bb1}
              alt='image'
              title='image'
              className='768-991:w-[40px] 768-991:h-[40px] 320-529:w-[25px] 320-529:h-[25px] 768-991:object-contain 1200-1300:w-[40px] 1200-1300:h-[40px] 1200-1300:object-contain group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
              priority
            />
            <h5 className='text-xl max-w-[200px] line-clamp-2 overflow-hidden 1440-1599:text-[18px] 320-529:text-[11px] 320-529:leading-[15px] 320-529:break-words 768-991:text-[16px] 768-991:w-full 768-991:text-center 992-1199:text-[16px] 1366-1439:text-[16px] 1200-1300:text-[16px] font-lato-bold w-[50%]  leading-[22px]  group-hover:text-white'>
              Latest Trip Reports
            </h5>
          </Link>
          <Link href='/specials-list' className='bottom-card max-w-[280px] min-h-[100px] 320-529:px-1 320-529:py-2 320-529:h-auto 320-529:min-h-full 768-991:flex-col 768-991:p-3 768-991:gap-3 768-991:w-[20%] 768-991:min-h-[120px] flex items-center gap-6 p-5 bg-[#D6E8EF] rounded-[10px] group transition duration-[.3s] ease-in-out hover:bg-[#3DABC2]'>
            <Image
              src={bb2}
              alt='image'
              title='image'
              className='768-991:w-[40px] 768-991:h-[40px] 320-529:w-[25px] 320-529:h-[25px] 768-991:object-contain 1200-1300:w-[40px] 1200-1300:h-[40px] 1200-1300:object-contain group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
              priority
            />
            <h5 className='text-xl max-w-[200px] line-clamp-2 overflow-hidden 1440-1599:text-[18px] 320-529:text-[11px] 320-529:leading-[15px] 320-529:break-words 768-991:text-[16px] 768-991:w-full 768-991:text-center 992-1199:text-[16px] 1366-1439:text-[16px] 1200-1300:text-[16px] font-lato-bold w-[50%]  leading-[22px]  group-hover:text-white'>
              Special Offers
            </h5>
          </Link>
          <Link href='/guided-group-trips?type=Whale Encounters' className='bottom-card max-w-[280px] min-h-[100px] 320-529:px-1 320-529:py-2 320-529:h-auto 320-529:min-h-full 768-991:flex-col 768-991:p-3 768-991:gap-3 768-991:w-[20%] 768-991:min-h-[120px] flex items-center gap-6 p-5 bg-[#D6E8EF] rounded-[10px] group transition duration-[.3s] ease-in-out hover:bg-[#3DABC2]'>
            <Image
              src={bb3}
              alt='image'
              title='image'
              className='768-991:w-[40px] 768-991:h-[40px] 320-529:w-[25px] 320-529:h-[25px] 768-991:object-contain 1200-1300:w-[40px] 1200-1300:h-[40px] 1200-1300:object-contain group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
              priority
            />
            <h5 className='text-xl max-w-[200px] line-clamp-2 overflow-hidden 1440-1599:text-[18px] 320-529:text-[11px] 320-529:leading-[15px] 320-529:break-words 768-991:text-[16px] 768-991:w-full 768-991:text-center 992-1199:text-[16px] 1366-1439:text-[16px] 1200-1300:text-[16px] font-lato-bold w-[50%]  leading-[22px]  group-hover:text-white'>
              Whale Trips
            </h5>
          </Link>
          <Link href='/guided-group-trips?type=African Safari' className='bottom-card max-w-[280px] min-h-[100px] 320-529:px-1 320-529:py-2 320-529:h-auto 320-529:min-h-full 768-991:flex-col 768-991:p-3 768-991:gap-3 768-991:w-[20%] 768-991:min-h-[120px] flex items-center gap-6 p-5 bg-[#D6E8EF] rounded-[10px] group transition duration-[.3s] ease-in-out hover:bg-[#3DABC2]'>
            <Image
              src={bb4}
              alt='image'
              title='image'
              className='768-991:w-[40px] 768-991:h-[40px] 320-529:w-[25px] 320-529:h-[25px] 768-991:object-contain 1200-1300:w-[40px] 1200-1300:h-[40px] 1200-1300:object-contain group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
              priority
            />
            <h5 className='text-xl max-w-[200px] line-clamp-2 overflow-hidden 1440-1599:text-[18px] 320-529:text-[11px] 320-529:leading-[15px] 320-529:break-words 768-991:text-[16px] 768-991:w-full 768-991:text-center 992-1199:text-[16px] 1366-1439:text-[16px] 1200-1300:text-[16px] font-lato-bold w-[50%]  leading-[22px]  group-hover:text-white'>
              African Safari
            </h5>
          </Link>
          <Link href='/article-list' className='bottom-card max-w-[280px] min-h-[100px] 320-529:px-1 320-529:py-2 320-529:h-auto 320-529:min-h-full 768-991:flex-col 768-991:p-3 768-991:gap-3 768-991:w-[20%] 768-991:min-h-[120px] flex items-center gap-6 p-5 bg-[#D6E8EF] rounded-[10px] group transition duration-[.3s] ease-in-out hover:bg-[#3DABC2]'>
            <Image
              src={bb5}
              alt='image'
              title='image'
              className='768-991:w-[40px] 768-991:h-[40px] 320-529:w-[25px] 320-529:h-[25px] 768-991:object-contain 1200-1300:w-[40px] 1200-1300:h-[40px] 1200-1300:object-contain group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
              priority
            />
            <h5 className='text-xl max-w-[200px] line-clamp-2 overflow-hidden 1440-1599:text-[18px] 320-529:text-[11px] 320-529:leading-[15px] 320-529:break-words 768-991:text-[16px] 768-991:w-full 768-991:text-center 992-1199:text-[16px] 1366-1439:text-[16px] 1200-1300:text-[16px] font-lato-bold w-[50%]  leading-[22px]  group-hover:text-white'>
              Ideas & Inspirations
            </h5>
          </Link>
        </div>
      </section>
      <main className='bg-[#E4F4FB] overflow-x-hidden 2560:mt-[-1px]'>
        <section
          id='home-second-section'
          className='about-section w-full text-black bg-[#E4F4FB] flex flex-wrap py-32 768-991:px-6 768-991:py-16 768-991:pb-10 1440-1599:pb-20 992-1199:pb-20 992-1199:px-8 1366-1439:pb-20 1366-1439:px-16 1200-1300:px-16 px-48 2560:px-[440px] 1680-1919:px-32 1680-1919:-mt-[1px] 1600-1679:px-28 1600-1679:-mt-[1px] 1440-1599:px-[6.5rem] 1440-1599:-mt-[1px]'
        >
          <div className='w-full lg:w-7/12'>
            <h3 className='ps-44 pb-0 992-1199:ps-20 768-991:ps-0 768-991:pb-5 font-poppins-semiBold text-xs  tracking-[3.6px] uppercase text-[#3DABC2]'>
              LEARN, SHOOT, EXPLORE
            </h3>
            <div className='video-text'>
              <div className='video-left inline-flex 320-529:gap-3 gap-12 992-1199:gap-6 items-center'>
                <Image
                  src={abLeft}
                  className='w-[142px] h-[74px] 320-529:w-[100px] 320-529:h-[55px] 992-1199:w-[130px] 992-1199:h-[65px] object-cover rounded-[100px]'
                  alt='image'
                  title='image'
                />
                <h2 className='font-lato-black text-[68px] 320-350:text-[24px] 320-529:text-[30px] 320-529:leading-[10px] 768-991:text-[50px] 768-991:leading-[60px] 1440-1599:text-[55px] 992-1199:text-[42px] 992-1199:leading-[55px] 1366-1439:text-[50px] 1366-1439:leading-[66px] 1200-1300:text-[50px] 1200-1300:leading-[66px]  leading-[84px] 1440-1599:leading-[76px]'>
                  Discover your
                </h2>
              </div>
              <h2 className='w-[74%] 1680-1919:w-[90%] 320-350:text-[24px] 320-529:text-[30px] 320-529:w-full 320-529:leading-[30px] 768-991:text-[50px] 768-991:w-[90%] 768-991:leading-[60px] 1600-1679:w-[90%] 1440-1599:w-[90%] 992-1199:text-[42px] 992-1199:leading-[65px] 992-1199:w-[85%] 1366-1439:text-[50px] 1366-1439:leading-[66px] 1366-1439:w-[85%] 1200-1300:text-[50px] 1200-1300:leading-[66px] 1200-1300:w-[85%] font-lato-black text-[68px] 1440-1599:text-[55px]  leading-[84px] 1440-1599:leading-[76px]'>
                next{' '}
                <span className='font-brownhill-script  leading-[84px] 320-529:leading-[45px] 320-529:px-2 320-529:text-[50px] 768-991:text-[80px] 768-991:pe-2 text-[130px] 1440-1599:text-[125px] 1440-1599:pe-2 992-1199:text-[95px] 992-1199:pe-2 1366-1439:text-[115px] 1366-1439:pe-2 1200-1300:text-[115px] 1200-1300:pe-2 text-[#3DABC2] px-4'>
                  adventure
                </span>
                with our worldwide travel
              </h2>
              <div className='video-right inline-flex gap-6 320-529:gap-3 items-center pt-3'>
                <h2 className='font-lato-black text-[68px] 320-350:text-[24px] 320-529:text-[30px] 320-529:leading-[30px] 768-991:text-[50px] 768-991:leading-[60px] 1440-1599:text-[55px] 992-1199:text-[42px] 992-1199:leading-[55px] 1366-1439:text-[50px] 1366-1439:leading-[66px] 1200-1300:text-[50px] 1200-1300:leading-[66px]  leading-[84px] 1440-1599:leading-[76px]'>
                  experts.
                </h2>
                <Image
                  src={abRight}
                  className='w-[235px] h-[74px] 320-529:w-[150px] 320-529:h-[60px] 992-1199:w-[190px] 992-1199:h-[65px] object-cover rounded-[100px]'
                  alt='image'
                  title='image'
                />
              </div>
            </div>
          </div>
          <div className='w-full lg:w-5/12'>
            <div className='flex flex-col h-full justify-center items-start gap-8 768-991:pt-10 1680-1919:ps-10 1600-1679:ps-10 1440-1599:ps-10 1366-1439:ps-10'>
              <Image
                src={wave}
                className='w-[73px] h-[20px] object-contain'
                alt='image'
                title='image'
              />
              {
                !loading ? <p
                  className='text-lg  leading-7 320-529:w-full 320-529:text-[16px] tracking-[-0.18px] font-poppins-medium w-[70%] 1260-1365:w-[90%] 992-1199:w-[100%] 992-1199:text-[15px] 1366-1439:w-[85%] 1366-1439:text-[16px] 1680-1919:w-[90%] 1600-1679:w-[85%] 1440-1599:w-[95%]'
                  dangerouslySetInnerHTML={{
                    __html:
                      page?.sections?.length > 0
                        ? page?.sections[0]?.description
                        : ''
                  }}
                ></p> : <div className="shimmers-text w-[70%] flex flex-col gap-3 animate-pulse">
                  <div className="w-full h-3 bg-slate-700 rounded-full"></div>
                  <div className="w-full h-3 bg-slate-700 rounded-full"></div>
                  <div className="w-full h-3 bg-slate-700 rounded-full"></div>
                  <div className="w-full h-3 bg-slate-700 rounded-full"></div>
                  <div className="w-[50%] h-3 bg-slate-700 rounded-full"></div>
                </div>
              }
              
              <Link
                href='/team'
                className='btn-hover bg-[#F57415] py-3 px-10  text-base 320-529:py-3 320-529:px-8 320-529:text-[15px] 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] text-white rounded-full border-[2px] border-solid border-[#F57415] flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#F57415]'
              >
                Meet us
                <Image src={arrowRight} alt='image' title='image' />
              </Link>
            </div>
          </div>
        </section>
        <section className='services w-full text-black flex flex-wrap pt-24 pb-44 px-48 2560:px-[440px] 320-529:pb-16 768-991:pb-32 768-991:gap-10 768-991:px-6 768-991:pt-16 1680-1919:px-32 1600-1679:px-28 1440-1599:px-[6.5rem] 992-1199:px-8 1366-1439:px-16 1200-1300:px-16 bg-[#E4F4FB] relative'>
          {page?.sections?.length > 1 && (
            <div className='w-full lg:w-3/12 768-991:h-auto 768-991:min-h-auto h-[282px] 992-1199:h-auto 992-1199:min-h-[300px] 1440-1599:h-[310px]'>
              <div className='flex flex-col h-full items-start gap-3 border border-solid border-[#B6D6E2] pb-7 320-529:px-5 992-1199:px-6 px-10 rounded-[20px]'>
                <div className='bg-white p-5 rounded-[20px] 320-529:-mt-10 -mt-7 -ms-2 border-8 border-solid border-[#E4F4FB]'>
                  <Image src={bb9} alt='image' title='image' />
                </div>
                <h5 className='font-lato-bold text-[22px] 320-529:text-[18px] 320-529:mt-0 768-991:h-auto 1260-1365:mt-0 1440-1599:text-[18px] 992-1199:text-[16px] 992-1199:leading-[22px] 992-1199:h-auto 1366-1439:text-[17px]  leading-[26px] mt-4 overflow-hidden line-clamp-2  h-[68px]'>
                  {page?.sections[1]?.title}
                </h5>
                <p className='text-[#4A748B] text-sm  font-poppins-medium tracking-[-0.14px] overflow-hidden 1440-1599:pb-16'>
                  {page?.sections[1]?.description}
                </p>
              </div>
            </div>
          )}
          {page?.sections?.length > 2 && (
            <div className='w-full lg:w-3/12 768-991:h-auto 768-991:min-h-auto h-[282px] 992-1199:h-auto 992-1199:min-h-[300px] 1440-1599:h-[310px]'>
              <div className='flex flex-col h-full items-start gap-3 border border-solid border-[#B6D6E2] pb-7 320-529:px-5 992-1199:px-6 px-10 rounded-[20px]'>
                <div className='bg-white p-5 rounded-[20px] 320-529:-mt-10 -mt-7 -ms-2 border-8 border-solid border-[#E4F4FB]'>
                  <Image src={bb10} alt='image' title='image' />
                </div>
                <h5 className='font-lato-bold text-[22px] 320-529:text-[18px] 320-529:pt-0 768-991:h-auto 1260-1365:pt-0 1440-1599:text-[18px] 992-1199:text-[16px] 992-1199:leading-[22px] 992-1199:h-auto 1366-1439:text-[17px]  leading-[26px] pt-4 overflow-hidden line-clamp-2  h-[68px]'>
                  {page?.sections[2]?.title}
                </h5>
                <p className='text-[#4A748B] text-sm  font-poppins-medium tracking-[-0.14px] overflow-hidden'>
                  {page?.sections[2]?.description}
                </p>
              </div>
            </div>
          )}
          {page?.sections?.length > 3 && (
            <div className='w-full lg:w-3/12 768-991:h-auto 768-991:min-h-auto h-[282px] 992-1199:h-auto 992-1199:min-h-[300px] 1440-1599:h-[310px]'>
              <div className='flex flex-col h-full items-start gap-3 border border-solid border-[#B6D6E2] pb-7 320-529:px-5 992-1199:px-6 px-10 rounded-[20px]'>
                <div className='bg-white p-5 rounded-[20px] 320-529:-mt-10 -mt-7 -ms-2 border-8 border-solid border-[#E4F4FB]'>
                  <Image src={bb11} alt='image' title='image' />
                </div>
                <h5 className='font-lato-bold text-[22px] 320-529:text-[18px] 320-529:pt-0 768-991:h-auto 1260-1365:pt-0 1440-1599:text-[18px] 992-1199:text-[16px] 992-1199:leading-[22px] 992-1199:h-auto 1366-1439:text-[17px]  leading-[26px] pt-4 overflow-hidden line-clamp-2  h-[68px]'>
                  {page?.sections[3]?.title}
                </h5>
                <p className='text-[#4A748B] text-sm  font-poppins-medium tracking-[-0.14px] overflow-hidden '>
                  {page?.sections[3]?.description}
                </p>
              </div>
            </div>
          )}
          {page?.sections?.length > 3 && (
            <div className='w-full lg:w-3/12 768-991:h-auto 768-991:min-h-auto h-[282px] 992-1199:h-auto 992-1199:min-h-[300px] 1440-1599:h-[310px]'>
              <div className='flex flex-col h-full items-start gap-3 border border-solid border-[#B6D6E2] pb-7 320-529:px-5 992-1199:px-6 px-10 rounded-[20px]'>
                <div className='bg-white p-5 rounded-[20px] 320-529:-mt-10 -mt-7 -ms-2 border-8 border-solid border-[#E4F4FB]'>
                  <Image src={bb12} alt='image' title='image' />
                </div>
                <h5 className='font-lato-bold text-[22px] 320-529:text-[18px] 320-529:pt-0 768-991:h-auto 1260-1365:pt-0 1440-1599:text-[18px] 992-1199:text-[16px] 992-1199:leading-[22px] 992-1199:h-auto 1366-1439:text-[17px]  leading-[26px] pt-4 overflow-hidden line-clamp-2  h-[68px]'>
                  {page?.sections[4]?.title}
                </h5>
                <p className='text-[#4A748B] text-sm  font-poppins-medium tracking-[-0.14px] overflow-hidden '>
                  {page?.sections[4]?.description}
                </p>
              </div>
            </div>
          )}
          {
            loading && <div className="w-full grid grid-cols-4 992-1199:grid-cols-2 768-991:grid-cols-1 gap-3 pt-20 pb-24">
              <div className="w-full h-[256px] rounded-[20px] bg-slate-700 animate-pulse"></div>
              <div className="w-full h-[256px] rounded-[20px] bg-slate-700 animate-pulse"></div>
              <div className="w-full h-[256px] rounded-[20px] bg-slate-700 animate-pulse"></div>
              <div className="w-full h-[256px] rounded-[20px] bg-slate-700 animate-pulse"></div>
            </div>
          }
          <Image
            src={service}
            className='absolute bottom-0 left-0 w-full'
            alt='image'
            title='image'
          />
        </section>
        <section className='bg-[#D6E8EF] text-black relative pt-12 pb-20 px-48 2560:px-[440px] 768-991:px-6 992-1199:px-8 1680-1919:px-32 1600-1679:px-28 1440-1599:px-[6.5rem] 1366-1439:px-16 1200-1300:px-16'>
          <h6 className='font-poppins-semiBold text-xs  tracking-[3.6px] uppercase text-[#3DABC2]'>
            DEALS & SPECIALS
          </h6>
          <div className='top-text flex items-end justify-between pt-3'>
            <h4 className='text-[38px] 320-529:w-full 320-529:text-[24px] 320-529:leading-7 768-991:w-[40%] 768-991:text-[30px] 768-991:leading-8 1440-1599:text-[32px] 992-1199:text-[26px] 992-1199:leading-8 992-1199:w-[35%] font-lato-extrabold  leading-10 1366-1439:w-[30%] 1366-1439:text-[30px] 1366-1439:leading-8 1440-1599:leading-9 w-[25%] 1440-1599:w-[30%] 1600-1679:w-[28%]'>
              Dive Into Our latest Special Offers
            </h4>
            <Link
              href='/specials-list'
              className='btn-hover bg-[#F57415] 768-991:hidden py-3 px-10  text-base 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] text-white rounded-full border-[2px] border-solid border-[#F57415] flex items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#F57415]'
            >
              All deals
              <Image src={arrowRight} alt='image' title='image' />
            </Link>
          </div>

          <div className='w-full relative mt-[0.4rem] 768-991:mt-1 768-991:pb-6'>
            <div>
              {page?.specail_offers?.length > 0 ? (
                <Slider {...settings}>
                  {page?.specail_offers?.map((offer,index) => (
                    <div className='768-991:px-[30px]'>
                    <Link
                      href={(offer?.type === 'Resort')?'/resort/'+offer?.slug:'/liveaboard/'+offer?.parent_slug+'/'+offer?.slug}
                      className='cards inline-block h-full w-[95%] 768-991:w-full 992-1199:min-h-[450px] 992-1199:mx-auto 1680-1919:mx-auto 1600-1679:mx-auto 1440-1599:mx-auto bg-white rounded-[10px] 1366-1439:px-4 pb-5 px-5 my-[60px] transition duration-[.3s] ease-in-out group hover:bg-[#3DABC2]'
                    >
                      <div className='card-image translate-y-[-25px] relative overflow-hidden rounded-[10px]'>
                        <Image
                          src={offer?.image ? offer?.image : dd1}
                          className='rounded-[10px] h-[180px] w-full object-cover transition duration-[.6s] ease-in-out'
                          alt='image'
                          title='image'
                          width={100}
                          height={100}
                        />
                        <div className='card-image-text absolute top-2 w-full flex items-center justify-between px-[1.1rem]'>
                          <h4 className='text-[13px] font-poppins-semiBold  tracking-[0.13px] leading-6 text-white'>
                            {offer?.type}
                          </h4>
                          {offer?.is_discount === 1 && (
                            <span className='text-[12px] font-poppins-bold  tracking-[0.13px] leading-6 text-white bg-[#CB0202] p-1 px-3 rounded-[5px]'>
                              {offer?.discount_rate_off}% OFF
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='card-content px-4'>
                        <h4 className='h-[56px] line-clamp-2 text-lg 1260-1365:text-[16px] 992-1199:text-[16px] font-poppins-semiBold  tracking-[-0.18px] mb-2 text-black  group-hover:text-white'>
                          {offer?.title}
                        </h4>
                        <div className='card-info flex items-center gap-2 pb-2'>
                          <Image
                            src={calendarCheck}
                            alt='image'
                            title='image'
                            className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
                          />
                          <span className='text-[13px] font-poppins-semiBold  tracking-[0.13px] text-black  group-hover:text-white'>
                            {offer?.travel_by}
                          </span>
                        </div>
                        <div className='card-info flex items-center gap-2 pb-2 text-[13px] font-poppins-semiBold  tracking-[0.13px] text-black  group-hover:text-white'>
                          <Image
                            src={geo2}
                            alt='image'
                            title='image'
                            className='group-hover:filter group-hover:brightness-0 group-hover:invert-[1]'
                          />
                          {expandRegions[index] ||
                            (window.innerWidth >= 768 && window.innerWidth < 992
                                ? offer.regions.length <= 10
                                : offer.regions.length <= 30)
                                ? offer.regions
                                : `${offer.regions.substring(
                                    0,
                                    window.innerWidth >= 768 && window.innerWidth < 992
                                        ? 10
                                        : 20
                                )} ...`}

                            {offer.regions.length > 20 && (
                                <Link
                                    href={"javascript:void(0)"}
                                    className="text-[#3DABC2] text-[12px] text-nowrap group-hover:text-[#fff] text-decoration-underline"
                                    onClick={(e) => toggleExpandRegions(index, e)}
                                >
                                  {expandRegions[index] ? "Read less" : "Read more"}
                                </Link>
                            )}
                          {/* <span className='text-[13px] font-poppins-semiBold  tracking-[0.13px] text-black  group-hover:text-white'>
                            {offer?.regions}
                          </span> */}
                        </div>
                        <p
                          className='h-[68px] text-[13px] text-[#4A748B]  leading-5 tracking-[0.26px] font-poppins-regular pt-2 overflow-hidden line-clamp-3  group-hover:text-white'
                          dangerouslySetInnerHTML={{
                            __html: offer?.short_description
                          }}
                        ></p>
                      </div>
                      <div className='card-price pe-4 pt-5'>
                        {offer?.is_discount === 1 ? (
                          <>
                            <h6 className='text-[13px] font-poppins-semiBold  tracking-[0.13px] leading-6 line-through text-[#CB0202] text-right'>
                              {offer?.price}
                            </h6>
                            <h5 className='text-[22px] font-poppins-semiBold  leading-[22px] tracking-[-0.22px] text-right text-black  group-hover:text-white'>
                              <span className='text-[13px] pe-2 group-hover:text-white'>
                                from
                              </span>
                              {offer?.discount_price_off}
                            </h5>
                          </>
                        ) : (
                          <h5 className='text-[22px] font-poppins-semiBold  leading-[22px] tracking-[-0.22px] text-right text-black  group-hover:text-white'>
                            <span className='text-[13px] pe-2 group-hover:text-white'>
                              from
                            </span>
                            {offer?.price}
                          </h5>
                        )}
                      </div>
                    </Link>
                  </div>
                  ))}
                </Slider>
              ) : loading && <div className='grid grid-cols-4 1025-1230:grid-cols-3 777-991:grid-cols-2 530-767:grid-cols-1 320-529:grid-cols-1 gap-4 mt-16 w-full animate-pulse'>
                    <div className='h-[452px] bg-slate-700 rounded-[10px]'></div>
                    <div className='h-[452px] bg-slate-700 rounded-[10px] 530-767:hidden 320-529:hidden'></div>
                    <div className='h-[452px] bg-slate-700 rounded-[10px] 777-991:hidden 530-767:hidden 320-529:hidden'></div>
                    <div className='h-[452px] bg-slate-700 rounded-[10px] 1025-1230:hidden 777-991:hidden 530-767:hidden 320-529:hidden'></div>
                  </div>}
            </div>
          </div>

          <Link
            href='/specials-list'
            className='btn-hover bg-[#F57415] hidden 768-991:inline-flex 768-991:mt-8 320-529:py-3 320-529:px-8 320-529:text-[15px] py-4 px-10  text-base 992-1199:py-3 992-1199:px-8 992-1199:text-[15px] 1366-1439:py-3 1366-1439:px-8 1366-1439:text-[15px] text-white rounded-full border-[2px] border-solid border-[#F57415] items-center gap-3 font-poppins-semiBold transition duration-[.6s] ease-in-out hover:bg-transparent hover:text-[#F57415]'
          >
            All deals
            <Image src={arrowRight} alt='image' title='image' />
          </Link>

          <Image
            src={experience}
            className='absolute 320-529:-bottom-[14px] 768-991:-bottom-[30px] -bottom-[75px] 2560:rotate-[1deg] left-0 w-full pointer-events-none'
            alt='image'
            title='image'
          />
        </section>
        {page?.article_ideas?.length > 0 && (
          <UnforgettableExperiences article_ideas={page?.article_ideas} />
        )}
        {page?.article_trip_reports?.length > 0 && (
          <HomeBeneath article_trip_reports={page?.article_trip_reports} />
        )}
        <Testimonial />
        <Footer />
      </main>
    </>
  )
}
